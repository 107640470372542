import { destroySession } from 'core/services/storage'

export function handleResponse<T>(response: Response) {
  const { status } = response
  return response.text().then((text: string) => {
    const data = parseJson(text)
    if (!response.ok) {
      if ([401].includes(response.status)) {
        const { pathname } = window.location

        if (pathname === '/login') {
          destroySession()
          window.location.href = '/login'
        } else {
          if (pathname.includes('lojista')) destroySession()
          else destroySession()
          window.location.href = '/auto-atendimento'
        }
      }
      const error = (data && data.message) || data?.error?.message
      return { error, success: false, status: status, data: null }
    }
    return {
      error: null,
      success: false,
      status: status,
      data: data as T
    }
  })
}

function parseJson(data: string) {
  try {
    return data && JSON.parse(data)
  } catch (error) {
    return
  }
}
