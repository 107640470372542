import { lazy, Suspense } from 'react'

import { BaseLayout } from 'core/layouts/Base'
import Auth from 'core/routes/guards/Auth'

const Register = lazy(() => import('./pages/register'))
const View = lazy(() => import('./pages/view'))
const SetToken = lazy(() => import('./pages/set-token'))
const Detail = lazy(() => import('./pages/detail'))

const routes = [
  {
    path: '/lojista',
    element: <Auth component={BaseLayout} />,
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<>...</>}>
            <Register />
          </Suspense>
        )
      },
      {
        path: 'detalhe',
        element: (
          <Suspense fallback={<>...</>}>
            <Detail />
          </Suspense>
        )
      },
      {
        path: 'token',
        element: (
          <Suspense fallback={<>...</>}>
            <SetToken />
          </Suspense>
        )
      },
      {
        path: 'visualizar',
        element: (
          <Suspense fallback={<>...</>}>
            <View />
          </Suspense>
        )
      }
    ]
  },
  {
    path: '/cliente',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<>...</>}>
            <Register />
          </Suspense>
        )
      },
      {
        path: 'detalhe',
        element: (
          <Suspense fallback={<>...</>}>
            <Detail />
          </Suspense>
        )
      },
      {
        path: 'token',
        element: (
          <Suspense fallback={<>...</>}>
            <SetToken />
          </Suspense>
        )
      },
      {
        path: 'visualizar/:id',
        element: (
          <Suspense fallback={<>...</>}>
            <View />
          </Suspense>
        )
      }
    ]
  }
]

export default routes