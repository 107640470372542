import { createGlobalStyle, css } from 'styled-components'

import {
  MyriadProRegular,
  AvantGardeRegular,
  AvantGardeSemibold,
  AvantGardeBold
} from 'core/assets/fonts'
import margin from './margin'
import padding from './padding'
import text from './text'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'AvantGarde';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Avant Garde Bold'), local('Avant-Garde-Bold'),
        url(${AvantGardeBold}) format('ttf');
  }
  @font-face {
    font-family: 'AvantGarde';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Avant Garde Semibold'), local('Avant-Garde-Semibold'),
        url(${AvantGardeSemibold}) format('ttf');
  }
  @font-face {
    font-family: 'AvantGarde';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Avant Garde Regular'), local('Avant-Garde-Regular'),
        url(${AvantGardeRegular}) format('ttf');
  }

  @font-face {
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Myriad Pro Regular'), local('Myriad-Pro-Regular'),
        url(${MyriadProRegular}) format('woff');
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
    -webkit-font-smoothing: antialiased;
  }

  ${({ theme }) => css`
    body {
      font-family: ${theme.font.family.AvantGarde};
      font-size: ${theme.font.sizes.xxsmall};
      font-weight: ${theme.font.normal};
    }
    label,
    button,
    input {
      font-family: ${theme.font.family.MyriadPro};
    }
  `}

  ${margin}

  ${padding}
  
  ${text}
`
