import { css } from 'styled-components'

export default css`
  .m-xxxs {
    margin: ${({ theme }) => theme.spacings.xxxs};
  }

  .m-xxs {
    margin: ${({ theme }) => theme.spacings.xxs};
  }

  .m-xs {
    margin: ${({ theme }) => theme.spacings.xs};
  }

  .m-sm {
    margin: ${({ theme }) => theme.spacings.sm};
  }

  .m-md {
    margin: ${({ theme }) => theme.spacings.md};
  }

  .m-lg {
    margin: ${({ theme }) => theme.spacings.lg};
  }

  .m-xl {
    margin: ${({ theme }) => theme.spacings.xl};
  }

  .mb-nano {
    margin-bottom: ${({ theme }) => theme.spacings.nano};
  }

  .mb-xxxs {
    margin-bottom: ${({ theme }) => theme.spacings.xxxs};
  }

  .mb-xxs {
    margin-bottom: ${({ theme }) => theme.spacings.xxs};
  }

  .mb-xs {
    margin-bottom: ${({ theme }) => theme.spacings.xs};
  }

  .mb-sm {
    margin-bottom: ${({ theme }) => theme.spacings.sm};
  }

  .mb-md {
    margin-bottom: ${({ theme }) => theme.spacings.md};
  }

  .mb-lg {
    margin-bottom: ${({ theme }) => theme.spacings.lg};
  }

  .mb-xl {
    margin-bottom: ${({ theme }) => theme.spacings.xl};
  }

  .mr-xxxs {
    margin-right: ${({ theme }) => theme.spacings.xxxs};
  }

  .mr-xxs {
    margin-right: ${({ theme }) => theme.spacings.xxs};
  }

  .mr-xs {
    margin-right: ${({ theme }) => theme.spacings.xs};
  }

  .mr-sm {
    margin-right: ${({ theme }) => theme.spacings.sm};
  }

  .mr-md {
    margin-right: ${({ theme }) => theme.spacings.md};
  }

  .mr-lg {
    margin-right: ${({ theme }) => theme.spacings.lg};
  }

  .mr-xl {
    margin-right: ${({ theme }) => theme.spacings.xl};
  }

  .ml-xxxs {
    margin-left: ${({ theme }) => theme.spacings.xxxs};
  }

  .ml-xxs {
    margin-left: ${({ theme }) => theme.spacings.xxs};
  }

  .ml-xs {
    margin-left: ${({ theme }) => theme.spacings.xs};
  }

  .ml-sm {
    margin-left: ${({ theme }) => theme.spacings.sm};
  }

  .ml-md {
    margin-left: ${({ theme }) => theme.spacings.md};
  }

  .ml-lg {
    margin-left: ${({ theme }) => theme.spacings.lg};
  }

  .ml-xl {
    margin-left: ${({ theme }) => theme.spacings.xl};
  }

  .mt-nano {
    margin-top: ${({ theme }) => theme.spacings.nano} !important;
  }

  .mt-xxxs {
    margin-top: ${({ theme }) => theme.spacings.xxxs};
  }

  .mt-xxs {
    margin-top: ${({ theme }) => theme.spacings.xxs};
  }

  .mt-xs {
    margin-top: ${({ theme }) => theme.spacings.xs};
  }

  .mt-sm {
    margin-top: ${({ theme }) => theme.spacings.sm};
  }

  .mt-md {
    margin-top: ${({ theme }) => theme.spacings.md};
  }

  .mt-lg {
    margin-top: ${({ theme }) => theme.spacings.lg};
  }

  .mt-xl {
    margin-top: ${({ theme }) => theme.spacings.xl};
  }

  .my-xxxs {
    margin: ${({ theme }) => theme.spacings.xxxs} 0;
  }

  .my-xxs {
    margin: ${({ theme }) => theme.spacings.xxs} 0;
  }

  .my-xs {
    margin: ${({ theme }) => theme.spacings.xs} 0;
  }

  .my-sm {
    margin: ${({ theme }) => theme.spacings.sm} 0;
  }

  .my-md {
    margin: ${({ theme }) => theme.spacings.md} 0;
  }

  .my-lg {
    margin: ${({ theme }) => theme.spacings.lg} 0;
  }

  .my-xl {
    margin: ${({ theme }) => theme.spacings.xl} 0;
  }

  .mx-xxxs {
    margin: 0 ${({ theme }) => theme.spacings.xxxs};
  }

  .mx-xxs {
    margin: 0 ${({ theme }) => theme.spacings.xxs};
  }

  .mx-xs {
    margin: 0 ${({ theme }) => theme.spacings.xs};
  }

  .mx-sm {
    margin: 0 ${({ theme }) => theme.spacings.sm};
  }

  .mx-md {
    margin: 0 ${({ theme }) => theme.spacings.md};
  }

  .mx-lg {
    margin: 0 ${({ theme }) => theme.spacings.lg};
  }

  .mx-xl {
    margin: 0 ${({ theme }) => theme.spacings.xl};
  }
`
