import { Header } from 'core/components/header'
import { Outlet, useLocation } from 'react-router-dom'
import * as Styled from './styles'
import { SearchBox } from 'core/components/searchbox'
import { useMediaQuery } from '@mui/material'
export function BaseLayout() {
  const location = useLocation()
  const matches = useMediaQuery('(max-width: 425px)')

  function showHeaderCpfField(): boolean {
    if (
      location.pathname === '/auto-atendimento' ||
      location.pathname === '/auto-atendimento/' ||
      location.pathname === '/loja' ||
      location.pathname === '/loja/' ||
      matches
    )
      return false
    else if (location.pathname === '/') return false
    else return true
  }
  return (
    <Styled.Container>
      <Styled.ContainerHead />
      <Styled.Content>
        {showHeaderCpfField() && <SearchBox />}

        <Header hasCpfField={showHeaderCpfField()} />
        <Outlet />
      </Styled.Content>
    </Styled.Container>
  )
}
