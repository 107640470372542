import { getSession } from 'core/services/storage'
import { Navigate, Route } from 'react-router-dom'

interface Props {
  component: React.FC
  isHome: boolean
  path?: string
}
interface LoginResponse {
  AccessToken: string
  RefreshToken: string
}
export default function Auth({
  component: Component,
  isHome = false,
  path
}: Props) {
  const session = getSession<LoginResponse>(
    process.env.TOKEN_LOCALSTORAGE || ''
  )

  if (!session?.AccessToken) return <Navigate to='/login' />

  if (isHome) return <Navigate to='/loja' />

  if (path) return <Route path={path} element={<Component />} />

  return <Component />
}
