import { encode } from '../helper-utils/encript-data'

export function setSession<T>(key: string, data: T): boolean {
  try {
    window.localStorage.setItem(key, encode(JSON.stringify(data)))
    return true
  } catch (error) {
    return false
  }
}
