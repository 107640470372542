import NotFound from 'core/layouts/NotFound'

import AuthRouter from '../../views/auth/router'
import ClientRouter from '../../views/client/router'
import HomeRouter from '../../views/home/router'

const Routes = [
  ...HomeRouter,
  ...AuthRouter,
  ...ClientRouter,
  {
    path: '*',
    element: <NotFound />
  }
]

export default Routes
