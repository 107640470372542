import { Outlet } from 'react-router-dom'
import * as Styled from './styles'

export function BaseAuthLayout() {
  return (
    <Styled.Container>
      <Styled.ContainerHead />
      <Styled.Content>
        <Outlet />
      </Styled.Content>
    </Styled.Container>
  )
}
