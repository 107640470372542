import apiFetch from 'core/services/api'
import { ReactNode, createContext, useEffect, useState } from 'react'
import { CustomerApiResult, Fidelidade } from './models/customer-api-result'
import { useNavigate } from 'react-router-dom'
import { destroySession, getSession } from 'core/services/storage'
import { TokenApiResult } from 'core/services/model/token-api-result'
import { CompanyApiModel } from 'core/services/model/company-api-model'
import { setSession } from 'core/services/storage/localStorage/setSession'
import { setSearchedCpf } from 'core/services/storage/localStorage/setSearchedCpf'
import { notify } from 'core/services/notification'
import { CustomerApiCreate } from './models/customer-api-create'
import { CustomerApiUpdate } from './models/customer-api-update'
import { destroyCustomer } from 'core/services/storage/localStorage/destroyCustomer'

interface SearchCpfProviderProps {
  children: ReactNode
}
interface CustomerManagerContextData {
  customerCtx: CustomerApiResult | null
  loadCustomer: boolean
  customerLogged: boolean
  sendTokenDelay: boolean
  typedCpf: string
  getCustomer: (cpf: string, isStore?: boolean) => void
  requireToken: (cpf: string) => void
  updateTypedCpf: (cpf: string) => void
  logoutCustomer: () => void
  loginCustomer: (token: TokenApiResult) => void
  updateCustomerIncludesFidelidade: (customer: Fidelidade) => void
  createCustomer: (customer: CustomerApiCreate) => void
  updateCustomer: (customer: CustomerApiUpdate) => void
  updateCustomerContext: (customer: CustomerApiResult) => void
}

export const CustomerContext = createContext<CustomerManagerContextData>(
  {} as CustomerManagerContextData
)

export function CustomerManagerProvider({ children }: SearchCpfProviderProps) {
  const navigate = useNavigate()
  const [customerCtx, setCustomerCtx] = useState<CustomerApiResult | null>(null)
  const [customerLogged, setCustomerLogged] = useState<boolean>(false)
  const [sendTokenDelay, setSendTokenDelay] = useState<boolean>(false)
  const [loadCustomer, setLoadCustomer] = useState<boolean>(false)
  const [typedCpf, setTypedCpf] = useState<string>('')

  useEffect(() => {
    const customerLogged = getSession<TokenApiResult>(
      process.env.TOKEN_CLIENT_LOCALSTORAGE || ''
    )
    if (customerLogged.AccessToken) setCustomerLogged(true)
  }, [])

  function updateTypedCpf(cpf: string) {
    setTypedCpf(cpf)
  }

  async function getCustomer(cpf: string, isStore?: boolean) {
    setLoadCustomer(true)
    if (customerCtx) {
      setCustomerLogged(false)
      setCustomerCtx(null)
    }
    const customer = await apiFetch<CustomerApiResult>({
      method: 'get',
      baseUrl: process.env.CADASTRO_API_URL || '',
      url: `client`,
      params: isStore
        ? { Documento: cpf.replace(/\D/g, ''), isStore: true }
        : { Documento: cpf.replace(/\D/g, '') }
    })
    if (!customer.status || [403, 500, 502].includes(customer.status)) {
      notify.error('Algo deu errado. Tente novamente mais tarde')
      setLoadCustomer(false)
      return
    }
    if (!customer.status || [400, 409].includes(customer.status)) {
      setLoadCustomer(false)
      destroyCustomer()
      if (userVerify().logged) {
        navigate('/lojista')
      } else {
        navigate('/cliente')
      }
    }
    if (customer.status === 200) {
      setCustomerCtx(customer.data)
      const searchCustomer = customer.data as CustomerApiResult
      setLoadCustomer(false)
      setSession<CustomerApiResult>(
        process.env.CUSTOMER_LOCALSTORAGE || '',
        searchCustomer
      )
      if (userVerify().logged) {
        navigate('/lojista/detalhe')
      } else {
        navigate('/cliente/token', { state: 'NEW_CUSTOMER' })
      }
    } else {
      setCustomerCtx(null)
    }
    setSearchedCpf(
      process.env.SEARCHED_CPF_LOCALSTORAGE || '@unknow',
      cpf.replace(/\D/g, '')
    )
  }

  async function requireToken(cpf: string) {
    setLoadCustomer(true)
    setSendTokenDelay(true)
    const customer = await apiFetch<CustomerApiResult>({
      method: 'get',
      baseUrl: process.env.CADASTRO_API_URL || '',
      url: `client`,
      params: { Documento: cpf.replace(/\D/g, '') }
    })
    if (!customer.status || [500, 502].includes(customer.status)) {
      notify.error('Algo deu errado. Tente novamente mais tarde')
      setLoadCustomer(false)
      setSendTokenDelay(false)
      return
    }
    setLoadCustomer(false)
    setSendTokenDelay(false)
  }

  function userVerify() {
    const tokenUserSession = getSession<TokenApiResult>(
      process.env.TOKEN_LOCALSTORAGE || ''
    )
    const user = getSession<CompanyApiModel>(
      process.env.USER_LOCALSTORAGE || ''
    )

    const session = {
      logged: tokenUserSession.AccessToken ? true : false,
      user: !user.data ? null : user
    }
    return session
  }

  function updateCustomerIncludesFidelidade(fidelidade: Fidelidade): void {
    setCustomerCtx(
      (state) =>
        ({
          ...state,
          Fidelidade: fidelidade
        } as CustomerApiResult)
    )
  }

  async function createCustomer(customerCreate: CustomerApiCreate) {
    setLoadCustomer(true)
    const customer = await apiFetch<CustomerApiResult>({
      method: 'post',
      baseUrl: process.env.CADASTRO_API_URL || '',
      url: `users`,
      body: customerCreate
    })
    if ([403, 500, 502].includes(customer.status)) {
      notify.error('Algo deu errado, tente novamente mais tarde')
      setCustomerCtx(null)
      setLoadCustomer(false)
      return
    } else if ([400, 404, 409, 405].includes(customer.status)) {
      notify.error(String(customer.error))
      setCustomerCtx(null)
      setLoadCustomer(false)
      return
    } else {
      setLoadCustomer(false)
      const newCustomer = customer.data as CustomerApiResult
      if (!newCustomer?.Telefone) {
        newCustomer.Telefone = customerCreate.Telefone
      }
      setCustomerCtx((state) => ({ ...state, ...newCustomer }))
      setSendTokenDelay(true)
      setTimeout(() => {
        requireToken(newCustomer.Documento)
      }, 6000)

      if (userVerify().logged) {
        navigate('/lojista/token', { state: customerCtx })
      } else {
        navigate('/cliente/token', { state: customerCtx })
      }
    }
  }

  async function updateCustomer(customerUpdate: CustomerApiUpdate) {
    setLoadCustomer(true)
    const customer = await apiFetch<CustomerApiResult>({
      method: 'put',
      baseUrl: process.env.CADASTRO_API_URL || '',
      url: `client/${customerUpdate.Id}`,
      body: customerUpdate
    })
    if ([403, 500, 502].includes(customer.status)) {
      notify.error('Algo deu errado, tente novamente mais tarde')
      setLoadCustomer(false)
      return
    } else if ([400, 404, 409, 405].includes(customer.status)) {
      notify.error(String(customer.error))
      setLoadCustomer(false)
      return
    } else {
      notify.info('Dados atualizados com sucesso')
      setLoadCustomer(false)
      const newCustomer = customer.data as CustomerApiResult
      setCustomerCtx((state) => ({ ...state, ...newCustomer }))
      setSession<CustomerApiResult>(
        process.env.CUSTOMER_LOCALSTORAGE || '',
        customer.data as CustomerApiResult
      )
    }
  }

  function updateCustomerContext(customer: CustomerApiResult) {
    setCustomerCtx((state) => ({ ...state, ...customer }))
  }

  function logoutCustomer() {
    navigate('/auto-atendimento')
    destroySession()
    setCustomerLogged(false)
    setCustomerCtx(null)
  }

  function loginCustomer(token: TokenApiResult) {
    setSession<TokenApiResult>(
      process.env.TOKEN_CLIENT_LOCALSTORAGE || '',
      token
    )
    setCustomerLogged(true)
  }

  return (
    <CustomerContext.Provider
      value={{
        customerCtx,
        loadCustomer,
        customerLogged,
        sendTokenDelay,
        typedCpf,
        getCustomer,
        requireToken,
        updateTypedCpf,
        logoutCustomer,
        loginCustomer,
        updateCustomerIncludesFidelidade,
        updateCustomer,
        createCustomer,
        updateCustomerContext
      }}
    >
      {children}
    </CustomerContext.Provider>
  )
}
