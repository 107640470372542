import { decode } from '../helper-utils/encript-data'
export interface GetSessionProps {
  getSession: <Data>() => Data
}

export function getSession<Data>(key: string): Data {
  try {
    const session = window.localStorage.getItem(key)
    if (session) {
      const decodedData = JSON.parse(decode(session))
      return decodedData
    }
    return {} as Data
  } catch (error) {
    return {} as Data
  }
}
