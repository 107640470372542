import styled from 'styled-components'

export const Container = styled.header`
  position: relative;
  width: 100vw;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 1rem 1.5rem;
  background-color: ${({ theme }) => theme.colors.primaries.orange};
  transition: all 0.3s;

  @media only screen and (${({ theme }) => theme.device.tablet}) {
    align-items: start;
    height: 60px;
  }
  @media only screen and (${({ theme }) => theme.device.mobileL}) {
    &.has-cpf-field {
      height: 144px;
    }
    img {
      cursor: pointer;
      width: 100px;
      position: relative;
      top: 0px;
    }
  }

  img {
    &.image-icon {
      width: 24px;
      position: relative;
      top: 2px;
    }
  }
  .s-sair {
    top: 3px;
    position: relative;
  }

  img {
    cursor: pointer;
    width: 150px;
    position: relative;
    top: 20px;

    @media only screen and (${({ theme }) => theme.device.mobileL}) {
      cursor: pointer;
      width: 100px;
      position: relative;
      top: 0px;
    }
  }

  span {
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
    gap: 4px;
    display: flex;
    align-items: flex-end;
    color: ${({ theme }) => theme.colors.basics.grey};

    svg {
      font-size: 18px;
      color: ${({ theme }) => theme.colors.basics.grey};
    }
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 50%;
    max-width: 400px;
    gap: 2rem;
    font-family: ${({ theme }) => theme.font.family.MyriadPro};
    color: ${({ theme }) => theme.colors.basics.white};
    span {
      color: inherit;
      svg {
        color: inherit;
      }
    }
    span.logout {
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    @media only screen and (${({ theme }) => theme.device.tablet}) {
      justify-content: end;

      span.logout {
        display: none;
      }
    }
  }
`
