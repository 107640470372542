export interface DestroySessionProps {
  destroyCustomer: () => boolean
}

export function destroyCustomer(): boolean {
  try {
    window.localStorage.removeItem(
      process.env.CUSTOMER_LOCALSTORAGE || '@unknow'
    )
    return true
  } catch (error) {
    return false
  }
}
