import { decode } from '../helper-utils/encript-data'

export function getSearchedCpf(): string {
  try {
    const session = window.localStorage.getItem(
      process.env.SEARCHED_CPF_LOCALSTORAGE || ''
    )
    if (session) {
      const decodedData = JSON.parse(decode(session))
      return decodedData
    }
    return ''
  } catch (error) {
    return ''
  }
}
