import styled from 'styled-components'

interface ContainerSearchProps {
  userLogged: boolean
}

export const ContainerSearch = styled.div<ContainerSearchProps>`
  position: absolute;
  top: 10px;
  z-index: 1;
  width: 40%;
  max-width: 500px;
  left: 21%;
  color: ${({ theme }) => theme.colors.primaries.orange};

  form {
    display: flex;
    gap: 10px;
    align-items: center;
    position: relative;

    div:first-child {
      flex-basis: ${(props) => (props.userLogged ? '90%' : '100%')};
    }

    button.copy {
      border: 0;
      background-color: transparent;
      cursor: pointer;
    }
    button.actions {
      position: absolute;
      border: 0;
      background-color: transparent;
      cursor: pointer;
      right: ${(props) => (props.userLogged ? '65px' : '15px')};
      top: 10px;
    }
  }

  @media only screen and (${({ theme }) => theme.device.tablet}) {
    position: absolute;
    top: 84px;
    padding-inline: 1rem;
    z-index: 1;
    width: 100%;
    max-width: 100%;
    left: 0;

    form {
      button.actions {
        right: 85px;
      }
    }
  }
  @media only screen and (${({ theme }) => theme.device.mobileL}) {
    form {
      button.actions {
        right: ${(props) => (props.userLogged ? '55px' : '15px')};
      }
    }
  }
`
