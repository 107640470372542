export const media = {
  mascote: require('core/assets/images/media/mascote.png'),
  mascote2: require('core/assets/images/media/mascote2.png'),
  decoration1: require('core/assets/images/media/decoration1.svg'),
  decoration2: require('core/assets/images/media/decoration2.png'),
  decoration3: require('core/assets/images/media/decorarion3.png'),
  decoration4: require('core/assets/images/media/decoration4.png'),
  desconto_aniversario: require('core/assets/images/media/desconto_aniversario.png'),
  cashback: require('core/assets/images/media/cashback.png'),
  nerf: require('core/assets/images/media/nerf.webp'),
  pjmask: require('core/assets/images/media/pjmask.webp'),
  hotwheels: require('core/assets/images/media/hotwheels.png'),
  offers: require('core/assets/images/media/offers.svg'),
  smile: require('core/assets/images/media/smile.png'),
  sad: require('core/assets/images/media/sad.png'),
  ico_pb: require('core/assets/images/media/ico_pb_fill.png')
}
