import * as Styled from './styles'

export interface InputErrosProps {
  error?: string
  className?: string
}

function InputErros({ error, className }: InputErrosProps) {
  return <Styled.Error className={className}>{error ? error : ''}</Styled.Error>
}

export default InputErros
