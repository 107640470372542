import { lazy, Suspense } from 'react'

import { BaseLayout } from 'core/layouts/Base'
import Auth from 'core/routes/guards/Auth'
const Home = lazy(() => import('./pages/home'))

const routes = [
  {
    path: '/',
    element: <Auth component={BaseLayout} />,
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<>...</>}>
            <Home />
          </Suspense>
        )
      }
    ]
  },
  {
    path: '/loja',
    element: <Auth component={BaseLayout} />,
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<>...</>}>
            <Home />
          </Suspense>
        )
      }
    ]
  },
  {
    path: '/auto-atendimento',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<>...</>}>
            <Home />
          </Suspense>
        )
      }
    ]
  }
]

export default routes