import InputErros from 'core/components/form/input-error'
import { useFormContext } from 'react-hook-form'
import * as Styled from './styles'
import { InputHTMLAttributes } from 'react'
import { icons } from 'core/assets'

export type InputProps = {
  placeholder: string
  name?: string
  mask?: string
  maskPlaceholder?: string | null
  className?: string
  type?: string
  errorMsg?: string
  disabled?: boolean
  label?: string
  onBlur?: (e: string) => void
} & InputHTMLAttributes<HTMLInputElement>

function Input({
  name,
  type = 'text',
  className,
  errorMsg,
  placeholder,
  mask,
  disabled = false,
  label,
  maskPlaceholder = null,
  onBlur,
  ...rest
}: InputProps) {
  const {
    register,
    formState: { errors }
  } = useFormContext()

  let errorField = errorMsg
  if (name && errors[name || '']?.message) {
    errorField = String(errors[name || '']?.message)
  }

  return (
    <Styled.Container
      className={className}
      errorField={errorField}
      disabled={disabled}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <Styled.InputMask
        color={'red'}
        id={name}
        disabled={disabled}
        className={label ? (className += ' mt-nano') : className}
        mask={mask || ''}
        maskPlaceholder={maskPlaceholder}
        alwaysShowMask={!!maskPlaceholder}
        placeholder={placeholder}
        type={type}
        {...rest}
        {...(!!name &&
          register(name, {
            onBlur(event) {
              !!onBlur && onBlur(event)
            }
          }))}
      />
      <span className={errorField ? 'error' : 'no-error'}>
        <img src={icons.alert.default} alt='ícone alerta erro' />
      </span>
      <InputErros className='position-error' error={errorField} />
    </Styled.Container>
  )
}

export default Input
