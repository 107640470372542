import 'styled-components'

export default {
  grid: {
    container: '130rem',
    gutter: '3.2rem'
  },
  border: {
    radius: '0.4rem'
  },
  font: {
    family: {
      AvantGarde:
        "AvantGarde, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      MyriadPro:
        "MyriadPro, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
    },
    light: 300,
    normal: 400,
    bold: 600,
    sizes: {
      xxsmall: '.8125rem',
      xsmall: '.875rem',
      small: '1rem',
      medium: '1.625rem',
      xmedium: '1.5rem',
      large: '2rem',
      xlarge: '3rem',
      xxlarge: '4rem',
      huge: '5.2rem'
    }
  },
  colors: {
    basics: {
      black: '#000000',
      darkGrey: '#444444',
      grey: '#AAAAAA',
      lightGrey: '#F1F1F1',
      white: '#FFFFFF'
    },
    primaries: {
      orange: '#F26522',
      lightOrange: '#F78E1E',
      green: '#009241',
      lightGreen: '#95C93D',
      blue: '#0083C5',
      lightBlue: '#19A3DD'
    },
    secondaries: {
      lightRed: '#F15F37',
      red: '#D83B25',
      darkRed: '#C4161C',
      lightPink: '#F9C0CA',
      medimPink: '#F386AF',
      pink: '#F0629E',
      lightPurple: '#9C8DC3',
      mediumPurple: '#8B6EB1',
      purple: '#7251A1'
    }
  },
  spacings: {
    quark: '0.25rem',
    nano: '0.5rem',
    xxxs: '1rem',
    xxs: '1.5rem',
    xs: '2rem',
    sm: '2.5rem',
    md: '3rem',
    lg: '3.5rem',
    xl: '4rem',
    xxl: '5rem',
    xxxl: '7.5rem',
    huge: '10rem',
    giant: '12.5rem'
  },
  device: {
    mobileS: '(max-width: 320px)',
    mobileM: '(max-width: 375px)',
    mobileL: '(max-width: 480px)',
    tablet: '(max-width: 768px)',
    tabletL: '(max-width: 900px)',
    laptop: '(max-width: 1024px)',
    laptopL: '(max-width: 1440px)',
    desktop: '(max-width: 2560px)'
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50
  },
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out'
  }
} as const
