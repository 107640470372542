export interface DestroySessionProps {
  destroySearchedCpf: () => boolean
}

export function destroySearchedCpf(): boolean {
  try {
    window.localStorage.removeItem(
      process.env.SEARCHED_CPF_LOCALSTORAGE || '@unknow'
    )
    return true
  } catch (error) {
    return false
  }
}
